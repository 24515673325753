.list {
    @apply mb-0 pt-2;

    .listItem {
        @apply text-secondary-500 font-bold text-xl cursor-pointer py-2;

        .dropdown {
            @apply hidden pt-4;
        }
    }

    .listItemActive {
        .dropdown {
            @apply block;
        }
    }
}
