.content {
    @apply block w-full h-auto;

    > div {
        @apply w-full block;

        > p {
            @apply text-gray-800;
        }
    }
}
