.list {
    @apply flex flex-row flex-wrap justify-start -mx-2;
    @apply xl:justify-start;

    .listItem {
        @apply flex justify-start items-center flex-row gap-3 w-full px-2 pb-2;
        @apply xl:w-1/3 xl:mb-10 xl:items-start;

        @screen xl {
            @apply pb-0;

            &:nth-child(3n + 2) {
                @apply pl-6;
            }
        }

        .icon {
            @apply w-[20px] h-[20px] flex justify-center items-center text-white rounded-full bg-secondary-500;

            img {
                @apply w-full h-full;
            }
        }

        .iconCharacter {
            @apply text-xs text-white rounded-full bg-secondary-500 mb-auto flex justify-center items-center;
            width: 24px;
            height: 24px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .iconMock {
            @apply text-xs text-white rounded-full bg-secondary-500 mb-auto flex justify-center items-center;
            width: 24px;
            height: 24px;
            flex-grow: 0;
            flex-shrink: 0;

            svg {
                @apply text-white m-auto;
            }
        }

        .content {
            @apply flex flex-col ml-2;

            > h6 {
                @apply uppercase;
            }

            > .heading {
                @apply text-left underline text-secondary-500;
            }

            > .info {
                @apply hidden text-sm font-normal text-left text-gray-700 capitalize;
                @apply xl:block;
            }
        }

        &:hover {
            .icon {
                @apply bg-primary-500;
            }

            .iconCharacter {
                @apply bg-primary-500;
            }

            .iconMock {
                @apply bg-primary-500;
            }

            .content {
                .heading {
                    @apply text-primary-500;
                }

                .info {
                    @apply text-primary-500;
                }
            }
        }
    }
}
