.wrapper {
    @apply flex flex-col;
    padding-top: 109px;

    main {
        @apply w-full h-full;
    }

    @screen 2xl {
        padding-top: 115px;
    }
}
