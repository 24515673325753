.wrapper {
    @apply flex flex-col px-4  border-l-2 border-white;

    h1 {
        @apply text-white;
    }

    span {
        @apply flex flex-row;
    }
}
