.wrapper {
    @apply relative;

    .carousal {
        @apply z-10 w-full mx-auto md:w-10/12 lg:w-11/12 2xl:w-full;
    }
}

.slideItem {
    min-height: calc(100vh - 171px);
    min-height: 600px;
}

.buttonWrapper {
    @apply relative bottom-0 left-0 right-0 flex items-center z-0;
    @apply md:absolute md:top-0 md:-left-8 md:-right-8;
    @apply xl:mb-52;
    @apply 2xl:-left-28 2xl:-right-28;

    > .prev {
        @apply mr-auto mb-4;

        > button {
            @apply flex items-center justify-center w-16 h-10 rounded-md bg-secondary-200;

            > svg {
                @apply text-secondary-500;
            }
        }

        > span {
            @apply w-10 uppercase text-center text-sm font-semibold;
        }

        &:hover {
            @apply text-primary-500;

            > button {
                @apply bg-primary-400;

                > svg {
                    @apply text-primary-500;
                }
            }
        }
    }

    .next {
        @apply ml-auto mb-4 uppercase;

        > button {
            @apply flex items-center justify-center w-16 h-10 rounded-md bg-secondary-200;

            > svg {
                @apply text-secondary-500;
            }
        }

        > span {
            @apply w-10 uppercase text-center text-sm font-semibold;
        }

        &:hover {
            @apply text-primary-500;

            > button {
                @apply bg-primary-400;

                > svg {
                    @apply text-primary-500;
                }
            }
        }
    }
}
