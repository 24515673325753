.wrapper {
    @apply flex items-center justify-center w-full h-screen bg-white;

    > div {
        @apply flex flex-col justify-center items-center;

        > h4 {
            @apply mt-2 text-primary-900;
        }
    }
}
