.wrapper {
    @apply flex flex-col items-center gap-4;
    @apply lg:flex-row;

    > .image {
        @apply w-6/12 h-full;

        > img {
            @apply w-full h-full;
        }
    }
}

.tableFinancial {
    @apply block overflow-x-auto;

    > table {
        @apply border-collapse border-spacing-0 w-full;
        @apply xl:table xl:table-auto xl:w-full;

        > tbody {
            > tr {
                @apply border-b border-secondary-500;

                > td {
                    @apply border border-secondary-500 p-1 font-semibold;
                }

                > td:first-child {
                    @apply border-l-0 border-t-0;
                }

                > td:nth-child(2) {
                    @apply bg-secondary-500 bg-opacity-20 text-center;
                }

                > td:nth-child(3) {
                    @apply bg-primary-500 bg-opacity-20 text-center;
                }

                > td:nth-child(4) {
                    @apply bg-secondary-500 bg-opacity-20 text-center;
                }
            }

            > tr:first-child {
                @apply border-b-4 border-gray-500;

                > td:first-child {
                    @apply text-secondary-500;
                }

                > td:nth-child(2) {
                    @apply bg-secondary-500 text-white;
                }

                > td:nth-child(3) {
                    @apply bg-primary-500 text-white;
                }
                > td:nth-child(4) {
                    @apply bg-secondary-500 text-white;
                }
            }

            > tr:last-child {
                @apply border-b-0;

                > td {
                    @apply border-b-0 border-l-0;
                }

                > td:nth-child(2) {
                    @apply bg-secondary-500 text-white font-semibold;
                }

                > td:nth-child(3) {
                    @apply bg-primary-500 text-white font-semibold;
                }

                > td:nth-child(4) {
                    @apply bg-secondary-500 text-white font-semibold;
                }
            }
        }
    }
}
