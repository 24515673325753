.picture {
    @apply bg-center bg-no-repeat bg-cover w-auto pt-36 overflow-hidden;
    height: calc(100vh - 109px);

    > .theme {
        @apply fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-opacity-10 z-[1];
        background-image: linear-gradient(transparent, rgba(black, 0.8));
    }

    > .overlay {
        @apply fixed top-0 left-0 h-full w-full flex justify-between z-0;

        :first-child {
            @apply -ml-[32rem] mt-0;
        }

        :last-child {
            @apply -mr-64 mt-32;
        }
    }

    > .container {
        @apply fixed top-0 left-0 right-0 z-[2] h-full w-full;
    }

    @screen xl {
        height: calc(100vh - 115px);
    }

}
