.wrapper {
    @apply relative w-full flex justify-center items-center;
    height: 100vh;
    min-height: 500px;
}

.modal {
    @apply absolute top-1/2 left-1/2 w-80 h-60 bg-white shadow-md p-4 rounded-xl;
    transform: translate(-50%, -50%);
}
